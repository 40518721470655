
import axios from 'axios'
import { defineComponent, inject, ref } from 'vue'
import { AuthPlugin } from '@/auth'

export default defineComponent({
  name: 'About',
  setup () {
    const idToken = ref()

    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }

    const getUser = async () => {
      const idToken = (await auth.getIdTokenClaims({}))?.__raw
      const response = await axios.get('https://aimeat-dev.appspot.com/v1/user', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      console.log(response)
    }
    getUser();

    (async () => {
      idToken.value = (await auth.getIdTokenClaims({}))?.__raw
    })()

    return { idToken }
  },
})
